<template>
  <div>
    <b-card>
    <b-tabs>
      <b-tab active title="English">
        <b-card-text>
          <validation-observer ref="attractionForm">
            <b-form>
              <b-row>
                <b-col md="6" class="align-self-center">
                  <b-form-group class="mb-0">
                    <validation-provider
                      #default="{ errors }"
                      name="Attraction Title"
                      rules="required"
                    >
                      <b-form-input
                        v-model="attraction.title"

                        :state="errors.length > 0 ? false : null"
                        placeholder="Attraction Title"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>

                    <b-form-textarea
                      class="mt-2"
                      v-model="attraction.description"
                      id="textarea-rows"
                      placeholder="Description"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="2" class="align-self-center">
                  <ComponentMediaSelector
                  :params="{lang: null}"
                    :isMultiple="false"
                    @mediaSelected="handleMediaSelect"
                  />
                </b-col>
                <b-col v-if="attraction.icon" md="3">
                  <b-img
                    v-bind="mainProps"
                    rounded="circle"
                    alt="Circle image"
                    :src="attraction.icon"
                  />
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-text>
      </b-tab>
      <b-tab title="Hindi">
        <b-card-text>
          <b-form>
            <b-row>
              <b-col md="6" class="align-self-center">
                <b-form-group class="mb-0">
                  <b-form-input
                    v-model="attraction.translations.hi.title"
                    placeholder="Attraction Title (Hindi)"
                  />

                  <b-form-textarea
                    class="mt-2"
                    v-model="attraction.translations.hi.description"
                    id="textarea-rows"
                    placeholder="Description (Hindi)"
                  />
                </b-form-group>
              </b-col>
               <b-col md="2" class="align-self-center">
                  <ComponentMediaSelector
                    :params="{lang: 'hi'}"
                    :isMultiple="false"
                    @mediaSelected="handleMediaSelect"
                  />
                </b-col>
                <b-col v-if="attraction.translations.hi.icon" md="3">
                  <b-img
                    v-bind="mainProps"
                    rounded="circle"
                    alt="Circle image"
                    :src="attraction.translations.hi.icon"
                  />
                </b-col>
            </b-row>
          </b-form>
        </b-card-text>
      </b-tab>
      <b-tab title="Marathi">
        <b-card-text>
          <b-form>
            <b-row>
              <b-col md="6" class="align-self-center">
                <b-form-group class="mb-0">
                  <b-form-input
                    v-model="attraction.translations.mr.title"
                    placeholder="Attraction Title (Marathi)"
                  />

                  <b-form-textarea
                    class="mt-2"
                    v-model="attraction.translations.mr.description"
                    id="textarea-rows"
                    placeholder="Description (Marathi)"
                  />
                </b-form-group>
              </b-col>
               <b-col md="2" class="align-self-center">
                  <ComponentMediaSelector
                  :params="{lang: 'mr'}"
                    :isMultiple="false"
                    @mediaSelected="handleMediaSelect"
                  />
                </b-col>
                <b-col v-if="attraction.translations.mr.icon" md="3">
                  <b-img
                    v-bind="mainProps"
                    rounded="circle"
                    alt="Circle image"
                    :src="attraction.translations.mr.icon"
                  />
                </b-col>
            </b-row>
          </b-form>
        </b-card-text>
      </b-tab>
    </b-tabs>
  </b-card>
  <b-card class="mt-2">
      <b-row>
        <b-col>
          <b-button @click="updatAttraction" size="lg" block variant="primary"
            >Submit</b-button
          >
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import {
  BTabs,
  BTab,
  BCardText,
  BCard,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BImg,
  BFormTextarea,
} from "bootstrap-vue";
import ComponentMediaSelector from "@/views/Gallery/ComponentMediaSelector";
import axios from "axios";
import { getUserToken } from "@/auth/utils";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BTabs,
    BCardText,
    BTab,
    BCard,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    ComponentMediaSelector,
    BImg,
    BFormTextarea,
  },
  data() {
    return {
      required,
      mainProps: {
        width: 75,
        height: 75,
      },
      attractionID: "",
      attraction: {
        title: "",
        description: "",
        icon: "",
        translations: {
          hi: {
            title: "",
            description: "",
            icon: ""
          },
          mr: {
            title: "",
            description: "",
            icon: ""
          },
        },
      },
    };
  },
  mounted() {
      this.getPage();
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },

    getPage() {
      axios
            .get(
              `${process.env.VUE_APP_SERVER_URL}/hotel/attractions/get/${this.$route.params.slug}`,
              {
                headers: {
                  Authorization: `Bearer ${getUserToken()}`,
                },
              }
            )
            .then((response) => {
              this.attraction = response.data
              this.attractionID = response.data._id
              const { translations } = response.data
              console.log(translations)
              this.attraction.translations = {
                hi : {
                    title: translations && translations.hi.title ? translations.hi.title: "",
            description:translations && translations.hi.description ? translations.hi.description: "",
            icon:translations && translations.hi.icon ? translations.hi.icon: ""
                },
                mr: {
                    title: translations && translations.mr && translations.mr.title ? translations.mr.title: "",
            description:translations && translations.mr && translations.mr.description ? translations.mr.description: "",
            icon:translations && translations.mr && translations.mr.icon ? translations.mr.icon: ""
                }
              }
            })
            .catch((error) => {
              console.log(error.response);
              this.makeToast(
                "danger",
                "Error",
                "Error while creating attraction!"
              );
            });
    },
    handleMediaSelect(mediaObject) {
      console.log("Media Recieved", mediaObject);
      if (mediaObject.status) {
        if(mediaObject.params.lang) {
        this.attraction.translations[mediaObject.params.lang].icon = mediaObject.media.src;
        } else {
        this.attraction.icon = mediaObject.media.src;
        }
      }
    },
    updatAttraction() {
      this.$refs.attractionForm.validate().then((success) => {
        if (success) {
          axios
            .post(
              `${process.env.VUE_APP_SERVER_URL}/hotel/attractions/update`,
              { 'attractionID' :this.attractionID, 'updates': this.attraction},
              {
                headers: {
                  Authorization: `Bearer ${getUserToken()}`,
                },
              }
            )

.then((response) => {
              this.makeToast(
                "success",
                "Success",
                `${response.data.title} has been created successfully!`
              );
            })
            .catch((error) => {
              console.log(error.response);
              this.makeToast(
                "danger",
                "Error",
                "Error while creating attraction!"
              );
            });
        }
      });
    },
  },
};
</script>
 